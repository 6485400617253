<div>
    <ejs-kanban #kanbanctrl keyField='status' [dataSource]='dataManager' [cardSettings]='cardSettings' rowHeight='38'
        width='auto' height='auto' (actionComplete)="onActionComplete($event)"
        (cardClick)="onCardClick($event)"  (drag)="onDrag($event)" (actionBegin)="onActionBegin($event)"
        (cardDoubleClick)="disableCardDoubleClick($event)"
        [swimlaneSettings]='swimlaneSettings'  [dialogSettings]='dialogSettings'>
        <e-columns>
          <e-column headerText='New' [keyField]='ActionStatus.New'>
          </e-column>
          <e-column headerText='Reminder Sent' [keyField]='ActionStatus.ReminderSent'></e-column>
          <e-column headerText='Do Not Escalate' [keyField]='ActionStatus.DoNotEscalate'></e-column>
          <e-column headerText='Escalated' [keyField]='ActionStatus.Escalated'></e-column>
          <e-column headerText='Auto-resolved' [keyField]='ActionStatus.AutoResolve'></e-column>
        </e-columns>
        <ng-template #cardSettingsTemplate let-data>
            <div class='e-card-content card-content'>
                <table class="card-template-wrap">
                    <tbody>
                        <tr>
                            <td>
                                <a class="edit-name-link" title="Open slide out page" href="javascript:void(0)"
                                (click)="onClickAction(data)">{{data.subject}}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{data.companyName}}</td>
                        </tr>
                        <tr>
                            <td>{{data.action_Id}} {{data.occuredDateTime  | date: 'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>
    </ejs-kanban>
</div>
