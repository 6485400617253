import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, HostListener
  , inject, input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { RouterOutlet } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { ItmToolbarComponent } from '@itm/components/toolbar';
import * as atlas from "azure-maps-control";
import { takeUntil, tap } from 'rxjs/operators';
import { ActionAlertSubType, ActionType, IActions } from '../../../utilities';
import { environment } from "environments/environment";
import { MapComponent } from 'app/modules/admin/shared/maps.component';
import { TextAreaModule } from '@syncfusion/ej2-angular-inputs';
import { CacNotifyService } from 'app/modules/admin/shared/services/cac.notify.service';
import { Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ItmToastrService } from '@itm/services';
import moment from 'moment-timezone';
import { Utilities } from '@itm/constants/utilities';
import { GoogleMapEmbededComponent } from 'app/modules/admin/shared/google-map-embeded/google-map-embeded.component';

@Component({
    selector: 'alert-sidebar',
    templateUrl: './alert-sidebar.component.html',
    styleUrls: ['./alert-sidebar.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterOutlet, CommonModule, ItmToolbarComponent, FuseAlertComponent
      , MatInputModule, MapComponent, TextAreaModule, FormsModule, GoogleMapEmbededComponent],
    providers: [
    ],
})
export class AlertSidebarComponent implements OnInit, OnDestroy  {

  additionalHeight: number = 70;
  containerHeight: any = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const innerWidth = event.target.innerWidth;
    const innerHeight = event.target.innerHeight;
    this.containerHeight = innerHeight - this.additionalHeight;

    const parentDiv = document.getElementById('innerContainer');
    if (parentDiv) {
      (parentDiv as HTMLElement).style.height = this.containerHeight + 'px';
    }
  }

  keyS0: string = environment["AZURE_MAPS_KEY_S0"];
  keyS1: string = environment["AZURE_MAPS_KEY_S1"];
  mapStyle: any = "road";

  @Output() fireReminderHourEvent = new EventEmitter<any>();

  @Output() fireDoNotEscalateEvent = new EventEmitter<any>();

  actionData = input<IActions>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _cacNotifyService = inject(CacNotifyService);
  private readonly _toastr = inject(ItmToastrService);

  public map: atlas.Map;

  showReasonSec: boolean = false;
  ActionType = ActionType;
  ActionAlertSubType = ActionAlertSubType;

  postData: any =  {
    reason: ''
  };

  constructor() {
    effect(() => {
      // this.showReason = false;
      // this._changeDetectorRef.detectChanges();
      // console.log(this.actionData())
    })
  }

  ngOnInit(): void {
    this.setInnerContainerHeight();
    
    this._cacNotifyService.sidebarOpenedStatus$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(x => {
      this.showReasonSec = false;
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
  }

  onRemindHourClick(e: any){
    this.fireReminderHourEvent.emit({target: e});
  }

  onDoNotEscalateClick(e: any){
    if(this.showReasonSec == false){
      this.showReasonSec = true;
      this.postData.reason = '';
    }
    else {
      if(!this.postData.reason){
        this._toastr.showInfo('Reason can not empty.')
        return;
      }      
      this.fireDoNotEscalateEvent.emit({target: e, data: this.postData });
    }
  }

  setInnerContainerHeight(){
    const innerHeight = window.innerHeight;
    this.containerHeight = innerHeight - this.additionalHeight;

    const parentDiv = document.getElementById('innerContainer');
    if (parentDiv) {
      (parentDiv as HTMLElement).style.height = this.containerHeight + 'px';
    }
  }

  formatDate(dateTime: string | null | undefined): string {
    if (!dateTime) return ''; // Handle null/undefined values
  
    return moment(dateTime)
      .tz(Utilities.DEFAULT_TIMEZONE) // Change to your desired timezone
      .format(Utilities.FORMAT_FULL_DATETIME); // Desired format
  }
}
