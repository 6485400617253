import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, Input, model, OnInit, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-google-map-embeded',
  templateUrl: './google-map-embeded.component.html',
  styleUrls: ['./google-map-embeded.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule
  ]
})
export class GoogleMapEmbededComponent implements OnInit {
  latitude = model<any>(undefined)
  longitude = model<any>(undefined)

  // iframeKey = computed(() => Date.now());
  iframeKey= signal<any>(Date.now());
  safeUrl= signal<any>('');

  query: any;
  constructor(private sanitizer: DomSanitizer) {
    effect(() => {
      // console.log(this.latitude(), this.longitude(), this.iframeKey())
      this.iframeKey.set(Date.now());
      this.setIFrameSaveUrl();
    }, { allowSignalWrites: true })
  }

  ngOnInit() {
    this.setIFrameSaveUrl();
  }

  setIFrameSaveUrl(){
    this.query = `https://maps.google.com/maps?q=${this.latitude()}%2C${this.longitude()}&t=k&z=17&ie=UTF8&iwloc=&output=embed`;
    this.safeUrl.set(this.sanitizer.bypassSecurityTrustResourceUrl(this.query));
  }

}

