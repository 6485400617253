import { DateTime } from "luxon";

export enum ActionStatus {
  New = 111,
  AutoResolve = 222,
  Resolved = 333, // DONE
  DoNotEscalate = 444,
  Escalated = 555,
  Reminder = 666,
  ReminderSent = 777,
  Archived = 999
}

export enum ActionType {
    Alert = 1,
    Report = 2,
    OffHireRequest = 3,
    OnHireRequest = 4
  }

  export const ActionTypeDisplayNames: { [key in ActionType]: string } = {
    [ActionType.Alert]: 'Alert',
    [ActionType.Report]: 'Report',
    [ActionType.OffHireRequest]: 'OffHire Request',
    [ActionType.OnHireRequest]: 'OnHire Request'
  };
  
  // Optional: Function to get display name by ActionType
  export function getActionTypeDisplayName(actionType: ActionType): string {
    return ActionTypeDisplayNames[actionType];
  }
  
export enum ActionAlertSubType {
  OutSideNotHire = 51,
  InSideOnHire = 52,
  OutSideStoodDownUsed = 53,
  OutSideOffHiredUsed = 54
}

  export declare interface IActions {
    action_Id: string;
    actionTypeId: number;
    actionSubTypeId: number;
    actionTitle: string;
    branchId: number;
    siteId: number;
    companyId: number;
    hireNo: number;
    utilisation: number|undefined;
    occuredDateTime: DateTime;
    subject: string;
    status: number;
    siteName: string|undefined;
    branchName: string|undefined;
    companyName: string|undefined;
    additional: IAdditional|undefined;
}

export declare interface IAdditional {
  category: string;
  description: string;
  standDownReason: string;
  utilisation: string;
  geoLocation: IGeoLocation;
  latGeolocation: any|undefined;
  lonGeolocation: any|undefined;
  equipments: IEquipment;
}

export declare interface IEquipment {
  equipmentNo: string;
  contractNo: string;
}

export declare interface IGeoLocation {
  latGeolocation: any|undefined;
  lonGeolocation: any|undefined;
}