<div class="mapouter">
    <div class="gmap_canvas">
        <iframe
            width="100%"
            height="100%"
            id="gmap_canvas"
            [attr.data-key]="iframeKey()" 
            [src]="safeUrl()"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0">
        </iframe>
        <a href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/">divi discount</a><br />
        <style>
            .mapouter {
                position: relative;
                text-align: right;
                height: 100%;
                width: 100%;
            }
        </style>
        <a href="https://www.embedgooglemap.net">embedding maps</a>
        <style>
            .gmap_canvas {
                overflow: hidden;
                background: none !important;
                height: 100%;
                width: 100%;
            }
        </style>
    </div>
</div>
