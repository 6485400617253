<div class="w-full itm-page-layout">
    @if (toolbar() != undefined && toolbar().yPosition=='top') {
        <div>
            <itm-toolbar [toolbarType]="toolbar()?.toolbarType" [buttons]="toolbar()?.buttons"
                [breadcrumb]="breadcrumb()"></itm-toolbar>
        </div>
        }

    <div class="w-full">
        <ejs-tab id="element"
        (selected)="clickTabs($event)" 
        (selecting)="select($event)" >
            <e-tabitems>
                <e-tabitem>
                    <ng-template #headerText>
                        <div> List View </div>
                     </ng-template>
                     <ng-template #content>
                         
                        <div id="gridWrapper" class="gridWrapper" [style.height.px]="gridHeight">
                            <ejs-grid #grid height='100%' [dataSource]='urlAdapterData' [loadingIndicator]='loadingIndicator'
                                [toolbar]='toolbarOptions' [allowGrouping]='true' [allowExcelExport]='true' [allowPdfExport]="true"
                                [allowReordering]="true" [allowResizing]="true" [allowPaging]='true' allowFiltering="true"
                                [pageSettings]='pageSettings' [selectionSettings]='selectionOptions' [allowSorting]="true"
                                showColumnChooser='true' rowHeight='38' gridLines='Both' width='auto' (dataBound)="dataBound()"
                                (excelQueryCellInfo)='excelQueryCellInfo($event)' (queryCellInfo)='customiseCell($event)'
                                (actionFailure)="fail($event)" [filterSettings]="filterSettings" [searchSettings]='searchSettings' 
                                [groupSettings]='groupOptions' (beforeDataBound)='beforeDataBound($event)' (actionBegin)="actionBegin($event)">
                                <e-columns>

                                    <!-- <e-column field='id' headerText='Id' isPrimaryKey="true" [visible]='false'></e-column> -->

                                    <e-column type='checkbox' allowFiltering='false' allowSorting='false' [width]='40' textAlign='Left'></e-column>

                                    <e-column field='action_Id' headerText="Action Id" visible='true' [allowSorting]='true'
                                        type='string' textAlign='Left' [width]='100'
                                        showInColumnChooser='false' [allowFiltering]='true'>
                                        <ng-template #template let-data>
                                            <a class="edit-name-link" title="Open slide out page" href="javascript:void(0)"
                                                (click)="onClickAction(data.id)">{{data.action_Id}}</a>
                                        </ng-template>
                                    </e-column>

                                    <e-column field='occuredDateTime' [headerText]='occuredDateTimeText' visible='true'
                                        type='date' textAlign='Center' [width]='150' [allowSorting]='true' [format]="{ type: 'dateTime', format: 'dd/MM/yyyy hh:mm a' }"
                                        [allowFiltering]='true'>
                                        <ng-template #template let-data>
                                            {{ formatByTimezone(data, 'occuredDateTime') }}
                                        </ng-template>
                                    </e-column>

                                    <e-column field='branchId' headerText='Branch ID' visible='true'
                                        type='number' textAlign='Left' [width]='100' [allowSorting]='true'
                                        [allowFiltering]='true'></e-column>
                                    
                                    <e-column field='companyName' headerText='Company' visible='true'
                                        type='string' textAlign='Left' [width]='200' [allowSorting]='true'
                                        [allowFiltering]='true'></e-column>
                                    
                                    <e-column field='subject' headerText='Subject' visible='true'
                                        type='string' textAlign='Left' [width]='200' [allowSorting]='true'
                                        [allowFiltering]='true'></e-column>
                                    
                                    <e-column field='equipmentNo' headerText='Equipment No' visible='true'
                                        type='string' textAlign='Left' [width]='150' [allowSorting]='true'
                                        [allowFiltering]='true'></e-column>
                                    
                                    <e-column field='contractNo' headerText="Contract No" visible='true'
                                        type='string' textAlign='Left' [width]='150' [allowSorting]='true'
                                        [allowFiltering]='true'></e-column>
                                    
                                    <e-column field='statusText' headerText='Status' visible='true' class="test"
                                        type='string' textAlign='Center' [width]='150' [allowSorting]='true'
                                        [allowFiltering]='true'>
                                        <ng-template #template let-data>
                                            <div class="status">
                                                {{data.statusText}}
                                            </div>
                                        </ng-template>
                                    </e-column>
                                    
                                </e-columns>
                            </ejs-grid>
                        </div>

                     </ng-template>
                </e-tabitem>

                <e-tabitem>
                    <ng-template #headerText>
                        <div> Kanban View </div>
                     </ng-template>
                     <ng-template #content>

                        <div class="kanbanWrapper" class="kanbanWrapper" [style.height.px]="kanbanHeight" style="overflow: auto">
                            <action-centre-kanban #kanban></action-centre-kanban>
                        </div>

                     </ng-template>
                </e-tabitem>
            </e-tabitems>
        </ejs-tab>
    </div>

    <div class="sidebar-container">
        <fuse-drawer
            class="w-screen min-w-screen sm:w-100 sm:min-w-160 z-999"
            fixed
            [mode]="'over'"
            [name]="'settingsDrawer'"
            [position]="'right'"
            (openedChanged)="drawerOpen($event)"
            #actionCentreDetailsSidebar>
            <div class="container">
                <sidebar-manager #sidebarManager [actionData]="selectedAction()" (firePopupCloseEvent)="closeClick()">
                    
                </sidebar-manager>
            </div>
        </fuse-drawer>
    </div>
</div>
